<template>
    <div v-if="$tenant != 'admin'">
        <h1>Sorry, you don't have access to this page.</h1>
    </div>
    <div v-if="$tenant == 'admin'">
    <div>
        <div style="flex-direction: column; justify-content: center; display: flex; padding: 10px; margin: 0;
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 25%;
  right: 25%;">
            <h3 class="text-center">Invite New User</h3>
            <form ref="form" @submit.prevent="sendEmail">
                <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" id="emailform" name="user_email" style="padding: 10px; width: 100%" required>
                </div>
                <div class="form-group">
                    <label>FH Short Name</label>
                    <input type="text" class="form-control" id="fhname" name="user_fh" style="padding: 10px; width: 100%" required>
                </div>
                <br>
                <div class="form-group" style="flex-direction: column; justify-content: center; display: flex; padding: 10px; margin: 0;
  position: absolute;
  left: 25%;
  right: 25%;">
                    <button class="btn btn-primary btn-block">Invite</button>
                </div>
                
                <div class="form-group" id="invitediv">
                    <label>Invite Link</label>
                    <input type="text" class="form-control" name="message" id="invite" style="padding: 10px">
                </div>
                <div class="form-group">
                    
                </div>
            </form>
        </div>
    </div>
    </div>
</template>
<script>
    import { db } from '../firebaseDb';
    //import emailjs from 'emailjs-com';
    export default {
        data() {
            return {
                message: "",
                email: "",
                user: {
                    username: "",
                    email: "",
                    fh: "",
                    invitelink: "",
                    approved: false,
                },
                show: false,
                noemail: true,
            }
        },
        created() {
            //this.user.fh = this.$tenant;
        },
        mounted() {
          document.getElementById("invitediv").style.visibility = "hidden";  
        },
        methods: {
            create_UUID(){
                var dt = new Date().getTime();
                var uuid = 'xxxx-4xxx-yxxx'.replace(/[xy]/g, function(c) {
                    var r = (dt + Math.random()*16)%16 | 0;
                    dt = Math.floor(dt/16);
                    return (c=='x' ? r :(r&0x3|0x8)).toString(16);
                });
                return uuid;
            },
            sendEmail() {
                this.user.username = "";
                this.user.email =  document.getElementById("emailform").value;
                this.user.fh = document.getElementById("fhname").value;
                this.user.invitelink = this.create_UUID();
                document.getElementById("invite").value = "https://criswellex.dd2a2285ryx7e.amplifyapp.com/signup/"+this.user.invitelink;
                //if(this.noemail == false) {
                  //try {
                //    emailjs.sendForm('service_2zqdy0k', 'template_gwgflfs', this.$refs.form, 'user_svTo7eY6baKWUo4qkwO8m').then(() => {
                //        db.collection('pendingusers').add(this.user).then(() => {
               //         alert("Added!");
               //     })
               // }).catch((error) => {
              //      console.log(error);
             //   });
               // this.$router.push('/admin')
            
                 // } catch(error) {
               //       console.log({error})
               //   }
               // }
               //else {
                var tenantuser = {};
                tenantuser.name = document.getElementById("fhname").value;
                    db.collection('tenants').add(tenantuser).then(() => {
                        alert("Added Tenant");
                    })
                    this.$router.push('/admin')
                    db.collection('pendingusers').add(this.user).then(() => {
                        alert("Added Pending User");
                    })
                    this.$router.push('/admin')
            //    }
                },
            onFormSubmit(event) {
                event.preventDefault()
                //send email.then() {
                db.collection('pendingusers').add(this.user).then(() => {
                    alert("Added!");
                    this.bl.name = ''
                    this.bl.notes = ''
                    
                }).catch((error) => {
                    console.log(error);
                });
                this.$router.push('/admin')
            }
        }
    }
</script>